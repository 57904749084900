import apiService from "./apiService";


export function getModulesApi(passaccess) {
    return apiService({
        url: `/module`,
        method: 'GET',
        passaccess
    });
}

export function getModuleByIdApi(id) {
    return apiService({
        url: `/module/${id}}`,
        method: 'GET'
    });
}

export function postModuleApi(payload) {
    return apiService({
        url: `/module`,
        data: payload,
        method: 'POST'
    });
} 
export function deleteModuleApi(id) {
    return apiService({
        url: `/module/${id}`,
        method: 'DELETE'
    });
} 
export function updateModuleApi(id, prettyName) {
    return apiService({
        url: `/module/${id}`,
        data: {prettyName},
        method: 'PATCH'
    });
} 