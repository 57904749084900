import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";


export function addEmployeeAPI(payload, module_id) {
    payload["schoolId"] = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/employee`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function importEmployeeAPI(file, module_id) {
    const schoolId = CommonUtil.getSelectedSchool()
    const formdata = new FormData();
    formdata.append("file", file)
    return apiService({
        url: `/employee/${schoolId}/bulkimport`,
        data: formdata,
        method: 'POST',
        headers: {
            "Content-Type": "form-data"
        },
        isFormData: true,
        module_id
    });
}

export function updateEmployeeByIdAPI(id, payload, module_id) {
    return apiService({
        url: `/employee/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}

export function getEmployeeByIdAPI(id, module_id) {
    return apiService({
        url: `/employee/get/${id}`,
        params: {},
        method: 'GET',
        module_id
    })
}
export function getFilteredEmployeesAPI(module_id) {
    return apiService({
        url: "/employee/employee-list/" + CommonUtil.getSelectedSchool(),
        method: 'GET',
        module_id
    })
}
export function deleteEmployeeAPI(id, module_id) {
    return apiService({
        url: `/employee/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function getEmployeesAPI(params, module_id) {
    return apiService({
        url: `/employee/${CommonUtil.getSelectedSchool()}`,
        params: params || {},
        method: 'GET',
        module_id
    })
}
export function getEmployeesHistoryAPI(params, module_id) {
    return apiService({
        url: `/employee/old/emp`,
        params: {
            schoolId: CommonUtil.getSelectedSchool(),
            ...params
        },
        method: 'GET',
        module_id
    })
}
export function addDepartmentAPI(name, module_id) {
    return apiService({
        url: `/addDepartment`,
        data: {
            "name": name,
            "schoolId": CommonUtil.getSelectedSchool()
        },
        method: 'POST',
        module_id
    })
}

export function addDesignationAPI(name, module_id) {
    return apiService({
        url: `/addDesignation`,
        data: {
            "name": name,
            "schoolId": CommonUtil.getSelectedSchool()

        },
        method: 'POST',
        module_id
    })
}

export function addEmployeeTypeAPI(name, module_id, paymentMode) {
    return apiService({
        url: `/addEmployeeType`,
        data: {
            "employeeType": name,
            "paymentMode" : paymentMode,
            "schoolId": CommonUtil.getSelectedSchool()
        },
        method: 'POST',
        module_id
    })
}
export function getEmployeeTypesAPI(module_id) {
    return apiService({
        url: `/addEmployeeType/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function getDepartmentsAPI(module_id) {
    return apiService({
        url: `/addDepartment/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function getDesignationsAPI(module_id) {
    return apiService({
        url: `/addDesignation/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function deleteDesignationAPI(id, module_id) {
    return apiService({
        url: `/addDesignation/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function deleteDepartmentAPI(id, module_id) {
    return apiService({
        url: `/addDepartment/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function deleteEmployeeTypeAPI(id, module_id) {
    return apiService({
        url: `/addEmployeeType/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function updateEmployeeTypeAPI(id, name, mode) {
    return apiService({
        url: `/addEmployeeType/${id}`,
        data: {
            "employeeType": name,
            "paymentMode": mode
        },
        method: 'PATCH',
    })
}

export function updateDepartmentAPI(id, name, module_id) {
    return apiService({
        url: `/addDepartment/${id}`,
        data: {
            "name": name,
        },
        method: 'PATCH',
        module_id
    })
}

export function updateDesignationAPI(id, name, module_id) {
    return apiService({
        url: `/addDesignation/${id}`,
        data: {
            "name": name,
        },
        method: 'PATCH',
        module_id
    })
}


export function getLeaveTemplatesAPI(module_id) {
    return apiService({
        url: `/leave/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}

export function getLeaveTemplateByIdAPI(id, module_id) {
    return apiService({
        url: `/leave/get/${id}`,
        method: 'GET',
        module_id
    })
}

export function deleteLeaveTemplateAPI(id, module_id) {
    return apiService({
        url: `/leave/${id}`,
        method: 'DELETE',
        module_id
    })
}

export function addLeaveTemplateAPI(payload, module_id) {
    payload["schoolId"] = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/leave`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function updateLeaveTemplateAPI(id, payload, module_id) {
    return apiService({
        url: `/leave/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}

export function getSalaryTempsAPI(module_id) {
    return apiService({
        url: `/addsalary/${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
        module_id
    })
}



export function addSalaryTemplateAPI(payload, module_id) {
    payload["schoolId"] = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/addsalary`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function updateSalaryTemplateAPI(id, payload, module_id) {
    return apiService({
        url: `/addsalary/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}
export function getEmpAttendanceAPI(params, module_id) {
    params["schoolId"] = CommonUtil.getSelectedSchool()
    params["sessionId"] = CommonUtil.getSelectedSession()?.id
    return apiService({
        url: `/employee-attendance`,
        params: params,
        method: 'GET',
        module_id
    })
}

export function getEmpAttendanceHistoryAPI(id, module_id) {
    return apiService({
        url: `/employee-attendance/history/` + id,
        method: 'GET',
        module_id
    })
}


export function addEmpAttendanceAPI(payload, module_id) {
    return apiService({
        url: `/employee-attendance`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function patchEmpAttendance(aId, isPresent, onLeave,isLate, remarks) {
    const payload = {
        "isPresent": (isPresent || isLate),
        "onLeave": onLeave,
        "isLate": isLate
    }
    if (remarks) {
        payload["remarks"] = remarks
    }
    return apiService({
        url: `/employee-attendance/${aId}`,
        data: payload,
        method: 'PATCH',
    })
}

export function geEmpAttReportAPI(params) {
    return apiService({
        url: "/employee-attendance/report",
        params,
        method: 'GET',
    })
}

export function getEmpReportAPI() {
    return apiService({
        url: "/employee/report/" + CommonUtil.getSelectedSchool(),
        method: 'GET',
        content_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
}

export function getEmpPayDataAPI(params) {
    params["schoolId"] = CommonUtil.getSelectedSchool()
    params["sessionId"] = CommonUtil.getSelectedSession()?.id
    return apiService({
        url: "/payslip",
        params,
        method: 'GET',
    })
}

export function transferEmp(id, schoolId) {
    return apiService({
        url: `/employee/transfer/${id}`,
        data: {
            "schoolId": schoolId
        },
        method: 'PATCH',
    })
}
export function getLastEmpCode() {
    return apiService({
        url: `/employee/code/emp?schoolId=${CommonUtil.getSelectedSchool()}`,
        method: 'GET',
    })
}

