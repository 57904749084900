import { RiSecurePaymentFill } from "react-icons/ri";
import { CgMail } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import React, { useContext, useState } from 'react'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CLoadingButton,
} from '@coreui/react-pro';
import "./fw.scss"
import { forgotPWAPI, verifyForgotPWAPI } from 'src/api/loginSignUpService';
import CommonUtil from 'src/shared/CommonUtil';
import moment from "moment";
import { Link, useNavigate } from 'react-router-dom';
import { BiLogIn } from 'react-icons/bi';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import toast from "react-hot-toast";
import mlcLogo from "src/assets/logos/mlc.png"
import { appContext } from "src/App";
import data from "src/data.json"



const ForgotPw = () => {

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [subdomain, setSubdomain] = useState('');

  const extractSubdomain = () => {
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split('.');
    if(hostnameParts.length > 3) {
      setSubdomain(hostnameParts[1]);
    }else{
      setSubdomain(hostnameParts[0]);
    }
  };


  React.useEffect(() => {
    extractSubdomain();
    if (CommonUtil.getLocalStorage('refresh_token_exp_time') &&
      moment(CommonUtil.getLocalStorage('refresh_token_exp_time')).diff(moment(), "seconds") > 0) {
      navigate('/dashboard');
    } else {
      CommonUtil.setLocalStorage('refresh_token_exp_time', null)
    };
  }, []);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (!otpSent) {
        const payload = {}
        if(!email){
          toast.error("Please enter email or phone number")
          throw new Error("Please enter email or phone number")
        }
        if (email.includes('@')) {
          payload.email = email
        }else if(email.length === 10){
          payload.phone = email
        }else{
          toast.error("Please enter valid phone number")
          throw new Error("Please enter valid phone number")
        }
        const resp = await forgotPWAPI(payload)
        if (resp?.code === 200) {
          toast.success(resp?.data?.message || "OTP sent successfully")
          setOtpSent(true)
        } else {
          toast.error(resp?.data?.message || "Something went wrong, please refresh the page")
          throw new Error(resp?.data?.message)
        }
      } else {
        const payload = {}
        if (!otp || !newPassword) {
          toast.error("Please enter OTP and new password")
          throw new Error("Please enter OTP and new password")
        }else if(newPassword.length < 6){
          toast.error("Password should be atleast 6 characters long")
          throw new Error("Password should be atleast 6 characters long")
        }else{
          payload.newPassword = newPassword
          payload.otp = otp
          if(email.includes('@')){
            payload.email = email
          }else{
            payload.phone = email
          }
        }
        const resp = await verifyForgotPWAPI(payload)
        if (resp?.code === 200) {
          toast.success(resp?.data?.message || "Password changed successfully, please login to continue")
          navigate('/')
          setOtpSent(false)
        } else {
          toast.error(resp?.data?.message || "Something went wrong, please refresh the page")
          throw new Error(resp?.data?.message)
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }


  return (
    <div className="login_page_container bg-light">
     <CHeader>
      <CContainer fluid>
        <CHeaderBrand href="/">
         <img src={mlcLogo} width="60px" alt="logo" className="me-2 rounded-circle" />
          MyLeading Campus®
        </CHeaderBrand>
      </CContainer>
    </CHeader>
      <div className="login_form_container">
        <div className="login_form" data-aos="fade-left">
          <form className="login_signup_form" onSubmit={handleLogin}>
            <div className="login_signup_form_header">
              {/* <img src={data.appAssets.find(asset => asset.subdomain === subdomain)?.schoolLogo || data.appAssets[0]?.schoolLogo} width="70px" alt="logo" /> */}
              <h1>Forgot Password</h1>
              {/* <small className="mt-2 text-info">
                Welcome to {data.appAssets.find(asset => asset.subdomain === subdomain)?.welcomeTagline || data.appAssets[0]?.welcomeTagline}
              </small> */}
            </div>
            <div className="login_signup_form_body">
              {
                !otpSent &&
                <div className="username_grp inp_grp">
                  <div className="icon">
                    <CgMail />
                  </div>
                  <input className="input" placeholder="Enter your phone number or email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
              }
              {
                otpSent &&
                <>
                  <div className="username_grp inp_grp">
                    <div className="icon">
                      <RiSecurePaymentFill />
                    </div>
                    <input className="input" type="number" placeholder="eg. 999999" id="otp" value={otp} onChange={e => setOtp(e.target.value)} />
                  </div>
                  <div className="password_grp inp_grp">
                    <div className="icon">
                      <RiLockPasswordFill />
                    </div>
                    <div className="pw_grp input">
                      <input className="" type={showPassword ? "text" : "password"} placeholder="Password" id="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                      <div className="show_pw_icon" onClick={e => setShowPassword(prev => !prev)} >
                        {
                          showPassword ? <AiFillEye className="text-black" /> : <AiFillEyeInvisible className="text-black" />
                        }
                      </div>
                    </div>
                  </div>
                </>
              }
              <div className="btn_grp">
                <CLoadingButton loading={loading} className="login_signup_btn text-white" color="info" type="submit">
                  <BiLogIn className="fs-4 me-2" /> {
                    otpSent ? "Login" : "Send OTP"
                  }
                </CLoadingButton>
                <div className="logo_container mt-3">
                  <img data-aos="zoom-in" src={mlcLogo} alt="logo" className="logo" />
                  <div className="heading" data-aos="fade-right">
                    powerd by <a href="https://myleadingcampus.com/" target="_blank" rel="noreferrer" > MyLeading Campus<sup>
                      ®
                    </sup> </a>
                  </div>
                  <div className="desc">
                  </div>
                </div>
                <div className="heading" data-aos="fade-right">
                  <Link to='/'>
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPw
