import React, { useState, useEffect } from 'react';
import { Button, Modal, Result } from 'antd';

const NetworkStatus = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Modal
      open={isOffline}
      footer={null}
      closable={false}
      centered
    >
      <Result
        status="warning"
        title="There was a problem with your network connection."
        extra={
          <Button type="primary" key="console" onClick={() => window.location.reload()}>
            Retry
          </Button>
        }
      />
    </Modal>
  );
};

export default NetworkStatus;
