import toast from "react-hot-toast";
import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";

export function uploadFile(payload, module_id) {
    const isPremium = CommonUtil.getLocalStorage("isPremium") === "true" ? true : false;
    const formdata = new FormData();
    const file = payload.file;

    const fileSizeInBytes = file.size;
    const fileSizeInKB = fileSizeInBytes / 1024;
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileSizeInKB > 1024 && fileExtension === 'pdf') {
        toast.error("File size is more than 1MB.");
        return
    }
    formdata.append("file", payload.file)
    formdata.append("type", payload.type)
    formdata.append("isPremium", isPremium)
    if(payload.studentId) {
        formdata.append("studentId", payload.studentId)
    }
    return apiService({
        url: "/file",
        data: formdata,
        method: 'post',
        headers: {
            "Content-Type": "form-data"
        },
        isFormData: true,
        module_id
    });
}

export function getFileAPI(fileName, module_id) {
    return apiService({
        url: "/file/" + fileName,
        method: 'GET',
        module_id
    });
}

export function encrypt(id) {
    return apiService({
        url: "/attendance/encrypt/" + id,
        method: 'GET',
        content_type: 'text/html',
    });
}
export function decrypt(id) {
    return apiService({
        url: "/attendance/decrypt/" + id,
        method: 'GET',
        content_type: 'text/html',
    });
}

export function getDaysAPI() {
    return apiService({
        url: `/day`,
        param: {},
        method: 'GET'
    });
}

export function getOtherConcessionsAPI(params={}) {
    return apiService({
        url: "/concession-for-service",
        params,
        method: 'GET'
    });
}

