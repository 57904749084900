import React, { useState } from "react";

const BarcodeScanner = () => {
  const [barcode, setBarcode] = useState("");
  const [device, setDevice] = useState(null);

  const connectScanner = async () => {
    try {
      const device = await navigator.usb.requestDevice({
        filters: [{ vendorId: "VBEQ8T019176" }], // Replace with your scanner's vendor ID
      });
      setDevice(device);
      await device.open();
      if (device.configuration === null) {
        await device.selectConfiguration(1);
      }
      await device.claimInterface(0);
      console.log("Scanner connected!");
    } catch (error) {
      console.error("Failed to connect to scanner:", error);
    }
  };

  const readBarcode = async () => {
    if (device) {
      try {
        const result = await device.transferIn(1, 64); // Endpoint and length depend on your scanner
        const textDecoder = new TextDecoder();
        const scannedData = textDecoder.decode(result.data);
        setBarcode(scannedData.trim());
      } catch (error) {
        console.error("Failed to read barcode:", error);
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>USB Barcode Scanner</h1>
      <button onClick={connectScanner}>Connect Scanner</button>
      <button onClick={readBarcode} disabled={!device}>
        Read Barcode
      </button>
      <p>Scanned Barcode: {barcode || "No input yet"}</p>
    </div>
  );
};

export default BarcodeScanner;