import apiService from "./apiService";


export function getRoleAccessApi(params, module_id, passaccess) {
    return apiService({
        url: `/role-user-module-access`,
        params: params,
        method: 'GET', 
        module_id,
        passaccess
    });
}

export function postRoleAccessApi(payload, module_id) {
    return apiService({
        url: `/role-user-module-access`,
        data: payload,
        method: 'POST', 
        module_id
    });
} 

export function deleteRoleAccessApi(payload, module_id) {
    return apiService({
        url: `/role-user-module-access/delete`,
        data: payload,
        method: 'POST', 
        module_id
    });
} 
