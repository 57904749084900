import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";

export function login(payload, callback) {
    return apiService({
        url: "/auth/login",
        data: payload,
        method: 'post'
    });
}
export function verifyForgotPWAPI(payload) {
    return apiService({
        url: "/auth/verify-otp-forgot-password",
        data: payload,
        method: 'post'
    });
}
export function forgotPWAPI(payload) {
    return apiService({
        url: "/auth/forgot-password",
        data: payload,
        method: 'post'
    });
}

export function loginWithPhone(payload) {
    return apiService({
        url: "/auth/login-with-otp",
        data: payload,
        method: 'post'
    });
}

export function verifyOtp(payload) {
    return apiService({
        url: "/auth/verify-otp",
        data: payload,
        method: 'post'
    });
}

export function logout(payload) {
    return apiService({
        url: "/auth/logout",
        data: payload,
        method: 'post',
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('refresh_token')
    });
}

export function sendOtp(payload) {
    return apiService({
        url: "/auth/send-phone-otp",
        data: payload,
        method: 'post'
    });
}




export function registerUser(payload, token) {
    return apiService({
        url: "/auth/register",
        data: payload,
        method: 'post'
    });
}

// /business/
export function getBusinessDetails() {
    return apiService({
        url: "/business/",
        // data: payload,
        method: 'get'
    });
}

export function registerBasicDetails(payload) {
    return apiService({
        url: "/business/",
        data: payload,
        method: 'PATCH',
        isFormData: true
    });
}


export function getRefreshToken(param) {
    return apiService({
        url: "/auth/refresh-token",
        params: param,
        method: 'get'
    });
}

export function forgotPassword(payload) {
    return apiService({
        url: "/auth/forgot-password",
        data: payload,
        method: 'post'
    });
}


// /auth/reset-password?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImlhdCI6MTY1ODAwMzI4NywiZXhwIjoxNjU4MDAzODg3LCJ0eXBlIjoicmVzZXRQYXNzd29yZCJ9.4pi3V5xQzFQbduP-GMLfZ9yic6S5RCp0QrW3ySHT4pM
export function resetPassword(payload, param) {
    return apiService({
        url: "/auth/reset-password?token="+param,
        data: payload,
        method: 'post'
    });
}


export function sendVerificationEmail(payload) {
    return apiService({
        url: "/auth/send-verification-email",
        data: payload,
        method: 'post'
    });
}

///auth/verify-email?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImlhdCI6MTY1ODAwNzU2NiwiZXhwIjoxNjU4MDA4MTY2LCJ0eXBlIjoidmVyaWZ5RW1haWwifQ.cYi5607dDjAGI-pdyJcFjYS4UdikvfnUfqQcCke6kuQ
export function verifyEmail(payload, param) {
    return apiService({
        url: "/auth/verify-email",
        data: payload,
        params: param,
        method: 'post'
    });
}


export function resetPasswordAPI(userId, payload) {
    return apiService({
        url: "/auth/reset-password",
        params: {
            userId
        },
        data: payload,
        method: 'POST'
    });
}

