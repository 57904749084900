import CommonUtil from "src/shared/CommonUtil";
import apiService from "./apiService";


export function getSessionsAPI(params = {}) {
    return apiService({
        url: `/session`,
        method: 'GET',
        params
    })
}
export function getSessionByIdAPI(id, module_id) {
    return apiService({
        url: `/session/${id}`,
        method: 'GET',
        module_id
    })
}

export function makeCurrentSessoinAPI(id, module_id) {
    const sessionType = CommonUtil.getLocalStorage("currentSessionType");
    return apiService({
        url: `/session/set-current-session/${id}`,
        params: {
            sessionType: +sessionType
        },
        method: 'GET',
        module_id
    })
}

export function addSessionsAPI(payload, module_id) {
    const sessionType = CommonUtil.getLocalStorage("currentSessionType");
    payload.sessionType = +sessionType
    return apiService({
        url: `/session`,
        data: payload,
        method: 'POST',
        module_id
    })
}
export function deleteSessionsAPI(id, module_id) {
    return apiService({
        url: `/session/${id}`,
        method: 'DELETE',
        module_id
    })
}
export function updateSessionsAPI(id, payload, module_id) {
    const sessionType = CommonUtil.getLocalStorage("currentSessionType");
    payload.sessionType = +sessionType
    return apiService({
        url: `/session/${id}`,
        data: payload,
        method: 'PATCH',
        module_id
    })
}